<template>
  <div class="invalid-images">
    <el-dialog :title="$t('tagger.invalidImages')" :visible.sync="dialogVisible" class="dialog-body">
      <div v-for="(item, index) of classifyList" :key="index">
        <el-row class="list-row">
          <el-col :span="6">
            <el-avatar
              class="table-avatar"
              shape="square"
              :size="80"
              fit="contain"
              :src="item.image.url | imageThumbnailUrl(isExternalImage)"
            ></el-avatar>
          </el-col>
          <el-col :span="18">
            <h4 class="title">{{item.image ? item.image.name : ""}}</h4>
            <p class="content">{{item.errInfo}}</p>
          </el-col>
        </el-row>
      </div>
      <pagination
        v-show="total>0"
        :total="total"
        :offset.sync="listQuery.offset"
        :limit.sync="listQuery.limit"
        layout="prev, pager, next, total"
        @pagination="fetchClassifyData"
      />
    </el-dialog>
  </div>
</template>

<script>
import { fetchClassify } from "@/api/task";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    },
    isExternalImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classifyList: [],
      listQuery: {
        hasError: true,
        offset: 0,
        limit: 5
      },
      total: 0
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {
    id() {
      this.fetchClassifyData();
    }
  },
  methods: {
    fetchClassifyData() {
      fetchClassify(this.id, this.listQuery).then(response => {
        this.classifyList = response.result.items;
        this.total = response.result.totalCount;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.invalid-images {
  .dialog-body /deep/ .el-dialog__body {
    background-color: #f5f7fa;
    padding-top: 0;
    padding-bottom: 0;
    overflow: auto;
  }

  .list-row {
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;

    .title {
      margin: 5px 0 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 22px;
    }
    .content {
      margin: 5px 0;
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>