<template>
  <canvas id="zone"></canvas>
</template>

<script>
import { imageUrl } from "@/filters";
import { getPixelRatio } from "@/utils";

export default {
  props: {
    current: {
      type: Object,
      default: () => {
        return {
          image: {}
        };
      }
    },
    isExternalImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ctxCanvas: null
    };
  },
  watch: {
    current() {
      this.updateCanvas();
    }
  },
  mounted() {
    var c = document.getElementById("zone");
    let ctx = c.getContext("2d");
    let ratio = getPixelRatio(ctx);
    c.style.height = 100 + "px";
    c.style.width = 100 + "px";
    c.width = 100 * ratio;
    c.height = 100 * ratio;
    ctx.scale(ratio, ratio);
    this.ctxCanvas = ctx;
    this.updateCanvas();
  },
  computed: {
    canvasSize() {
      if (!this.current || !this.current.image) {
        return {};
      }
      let data = this.current.image;
      let xmin = 0,
        ymin = 0,
        width = data.width,
        height = data.height;
      if (this.current.position) {
        let position = JSON.parse(this.current.position);
        xmin = position.xmin ? width * position.xmin : 0;
        ymin = position.ymin ? height * position.ymin : 0;
        width = position.xmax ? width * position.xmax - xmin : width;
        height = position.ymax ? height * position.ymax - ymin : height;
      }
      let cxmin = 0,
        cymin = 0,
        cwidth = 100,
        cheight = 100;
      if (width > height) {
        cheight = (height / width) * cwidth;
        cymin = (100 - cheight) / 2;
      } else {
        cwidth = (width / height) * cheight;
        cxmin = (100 - cwidth) / 2;
      }
      return {
        xmin,
        ymin,
        width,
        height,
        cxmin,
        cymin,
        cwidth,
        cheight
      };
    }
  },
  methods: {
    updateCanvas() {
      let ctx = this.ctxCanvas;
      ctx.clearRect(0, 0, 100, 100);
      if (!this.current || !this.current.image) {
        return {};
      }
      let {
        xmin,
        ymin,
        width,
        height,
        cxmin,
        cymin,
        cwidth,
        cheight
      } = this.canvasSize;
      const image = new window.Image();
      image.onload = function() {
        ctx.drawImage(
          image,
          xmin,
          ymin,
          width,
          height,
          cxmin,
          cymin,
          cwidth,
          cheight
        );
      };
      image.src = imageUrl(this.current.image.url, this.isExternalImage);
    }
  }
};
</script>
