<template>
  <div class="tagger-detail">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <div class="summary-content">
      <el-row>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("tagger.desc") }} :
          </div>
          <div class="summary-text">{{ this.task ? this.task.desc : "" }}</div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("tagger.defaultCategory") }} :
          </div>
          <div class="summary-text">
            {{
              (this.task && this.task.t1 !== "" ? this.task.t1 : "-") +
              " / " +
              (this.task && this.task.t2 !== "" ? this.task.t2 : "-")
            }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("tagger.status") }} :
          </div>
          <el-tag :type="this.task | statusFilter" effect="dark" size="small">{{
            this.task
              ? this.task.status === "waiting"
                ? $t("tagger.waiting")
                : this.task.status === "running"
                ? $t("tagger.running")
                : this.task.status === "done"
                ? $t("tagger.done")
                : this.task.status === "abort"
                ? $t("tagger.abort")
                : this.task.status === "error"
                ? $t("tagger.error")
                : this.task.status
              : "-"
          }}</el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("tagger.execAt") }} :
          </div>
          <div class="summary-text">
            {{ task && task.execAt ? task.execAt : "" | displayTime() }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("tagger.finishAt") }} :
          </div>
          <div class="summary-text">
            {{ task && task.finishAt ? task.finishAt : "" | displayTime() }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="summary-title summary-text">
            {{ $t("common.createAt") }} :
          </div>
          <div class="summary-text">
            {{ task && task.createdAt ? task.createdAt : "" | displayTime() }}
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="showClassify">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('tagger.category')">
          <el-select
            style="width: 128px"
            v-model="selectedT1"
            @change="fetchItem"
            clearable
          >
            <el-option
              v-for="item in optionsT1"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('tagger.itemType')">
          <el-select style="width: 128px" v-model="selectedT2" clearable>
            <el-option
              v-for="item in optionsT2"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="fetchClassifyData"
            >{{ $t("common.search") }}</el-button
          >
        </el-form-item>
      </el-form>
      <div class="summary-actions">
        <el-button
          v-if="this.task && this.task.execType != 'analysis'"
          size="small"
          type="warning"
          icon="el-icon-refresh-left"
          @click="restart"
          >{{ $t("tagger.restart") }}</el-button
        >
        <el-button
          size="small"
          class="excel-dl-btn"
          icon="el-icon-download"
          @click="exported"
          >{{ $t("common.download") }}</el-button
        >
        <el-button
          size="small"
          type="danger"
          icon="el-icon-warning-outline"
          @click="invalidDialogVisible = true"
          >{{ $t("tagger.invalidImages") }}</el-button
        >
      </div>
    </el-row>
    <el-table v-if="showClassify" :data="classifyList">
      <el-table-column prop="desc" :label="$t('common.image')" min-width="50">
        <template slot-scope="scope">
          <a @click="openDialog(scope.row)">
            <el-avatar
              class="table-avatar"
              shape="square"
              :size="80"
              fit="contain"
              :src="
                scope.row.image.url
                  | imageThumbnailUrl(task ? task.isExternalImage : false)
              "
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="image.name"
        :label="$t('common.name')"
      ></el-table-column>
      <el-table-column
        prop="t1"
        :label="$t('tagger.category')"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="t2"
        :label="$t('tagger.itemType')"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="t3"
        :label="$t('tagger.specificItem')"
        min-width="80"
      ></el-table-column>
      <el-table-column :label="$t('common.detail')" min-width="50">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            icon="el-icon-more"
            circle
            size="small"
            @click="openDialog(row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-if="showClassify"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchClassifyData"
    />
    <running
      v-if="task && !showClassify"
      :current="task"
      :abortCallback="fetchTask"
    />
    <el-dialog
      class="detail-dialog"
      :title="$t('common.detail')"
      :visible.sync="dialogVisible"
      width="1000px"
      @closed="closeDialog"
      top="4vh"
    >
      <el-row :gutter="18">
        <el-col :span="12">
          <el-row class="detail-img">
            <img
              class="img-frame"
              :style="imageOriginalSize"
              :src="
                this.current
                  ? this.current.image.url
                  : '' | imageUrl(task ? task.isExternalImage : false)
              "
              :alt="this.current ? this.current.image.name : null"
            />
          </el-row>
          <el-row :gutter="9">
            <el-col :span="6">
              <div class="frame">
                <crop-canvas
                  class="frame-zone"
                  :current="current"
                  :isExternalImage="task ? task.isExternalImage : false"
                />
              </div>
            </el-col>
            <el-col :span="6" v-for="(item, index) of colorStyles" :key="index">
              <div class="frame">
                <div class="frame-zone" :style="{ background: item }"></div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <div class="detail-desc">
            <table>
              <tbody>
                <tr class="desc-row">
                  <th class="desc-row-title">{{ $t("common.type") }}</th>
                </tr>
                <tr class="desc-row">
                  <td class="desc-row-content">
                    <span class="desc-content-label">{{
                      $t("tagger.category")
                    }}</span>
                    {{ this.current ? this.current.t1 : "" }}
                    <br />
                    <span class="desc-content-label">{{
                      $t("tagger.itemType")
                    }}</span>
                    {{ this.current ? this.current.t2 : "" }}
                    <br />
                    <span class="desc-content-label">{{
                      $t("tagger.specificItem")
                    }}</span>
                    {{ this.current ? this.current.t3 : "" }}
                    <br />
                  </td>
                </tr>
                <tr class="desc-row">
                  <th class="desc-row-title">{{ $t("common.detail") }}</th>
                </tr>
                <tr class="desc-row">
                  <td class="desc-row-content">
                    <div>
                      <span
                        v-if="this.current && this.current.color"
                        class="desc-content-label"
                        >Color</span
                      >
                      <div class="color-container">
                        <div
                          v-for="(item, index) of colorStyles"
                          :key="index"
                          class="color-block"
                          :style="{ background: item }"
                        ></div>
                      </div>
                    </div>
                    <p>
                      <span
                        v-if="this.current && this.current.pattern"
                        class="desc-content-label"
                        >Pattern</span
                      >
                      {{ this.current ? this.current.pattern : "" }}
                    </p>
                    <p>
                      <span
                        v-if="this.current && this.current.style"
                        class="desc-content-label"
                        >Style</span
                      >
                      {{ this.current ? this.current.style : "" }}
                    </p>
                    <p>
                      <span
                        v-if="this.current && this.current.collarNeck"
                        class="desc-content-label"
                        >Collar</span
                      >
                      {{ this.current ? this.current.collarNeck : "" }}
                    </p>
                    <p>
                      <span
                        v-if="this.current && this.current.fg"
                        class="desc-content-label"
                        >FG</span
                      >
                      {{ this.current ? this.current.fg : "" }}
                    </p>
                    <p>
                      <span
                        v-if="this.current && this.current.fit"
                        class="desc-content-label"
                        >Fit</span
                      >
                      {{ this.current ? this.current.fit : "" }}
                    </p>
                    <p>
                      <span
                        v-if="this.current && this.current['length']"
                        class="desc-content-label"
                        >Length</span
                      >
                      {{ this.current ? this.current["length"] : "" }}
                    </p>
                    <p>
                      <span
                        v-if="this.current && this.current.material"
                        class="desc-content-label"
                        >Material</span
                      >
                      {{ this.current ? this.current.material : "" }}
                    </p>
                  </td>
                </tr>
                <tr class="desc-row">
                  <th class="desc-row-title">Trend Point</th>
                </tr>
                <tr class="desc-row">
                  <td class="desc-row-content">
                    <p>
                      <span v-if="trendPoint" class="desc-content-label"
                        >Trend Point</span
                      >
                      {{ trendPoint }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <invalid-images
      :visible.sync="invalidDialogVisible"
      :isExternalImage="task ? task.isExternalImage : false"
      :id="task ? task.id : 0"
    ></invalid-images>
  </div>
</template>

<script>
import {
  fetchOne,
  fetchClassify,
  restart,
  fetchExport,
  fetchCategory,
  fetchItem,
} from "@/api/task";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import CropCanvas from "@/components/CropCanvas";
import Pagination from "@/components/Pagination";
import InvalidImages from "@/components/tagger/InvalidImages";
import Running from "@/components/tagger/Running";
import { ossRoot } from "@/utils/server";
export default {
  components: {
    AiBreadcrumb,
    CropCanvas,
    Pagination,
    InvalidImages,
    Running,
  },
  data() {
    return {
      task: null,
      classifyList: [],
      dialogVisible: false,
      invalidDialogVisible: false,
      current: null,
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      interval: null,
      timer: null,
      optionsT1: [],
      optionsT2: [],
      selectedT1: null,
      selectedT2: null,
    };
  },
  filters: {
    statusFilter(task) {
      if (!task) {
        return "info";
      }
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[task.status];
    },
  },
  created() {
    this.fetchTask();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    openDialog(row) {
      this.current = row;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.current = null;
    },
    nextDelay(len) {
      if (len >= 100) {
        return 4000;
      } else if (len >= 1000) {
        return 6000;
      }
      return 2000;
    },
    fetchTask() {
      if (this.$route.params && this.$route.params.id) {
        fetchOne(this.$route.params.id).then((response) => {
          this.task = response.result;
          if (
            response.result.status === "done" ||
            response.result.status === "error" ||
            response.result.status === "abort"
          ) {
            clearTimeout(this.timer);
            this.fetchClassifyData();
            this.fetchCategory();
          } else {
            this.timer = setTimeout(
              this.fetchTask,
              this.nextDelay(this.task.detectedCount)
            );
          }
        });
      } else {
        this.timer = setTimeout(this.fetchTask, 2000);
      }
    },
    fetchClassifyData() {
      if (!this.task.id) {
        return;
      }
      let params = { ...this.listQuery };
      if (this.selectedT1) {
        params.t1 = this.selectedT1;
      }
      if (this.selectedT2) {
        params.t2 = this.selectedT2;
      }
      fetchClassify(this.task.id, params).then((response) => {
        this.classifyList = response.result.items;
        this.total = response.result.totalCount;
      });
    },
    restart() {
      restart(this.task.id).then((response) => {
        if (response.success) {
          this.fetchTask();
        }
      });
    },
    exported() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      fetchExport(this.task.id).then((response) => {
        if (response.result && response.result.fileUrl !== "") {
          loading.close();
          clearInterval(this.timer);
          var a = document.createElement("a");
          a.href = ossRoot + "/" + response.result.fileUrl;
          a.download = "ai-tagger-" + this.task.id + "-" + Date.now() + ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        } else {
          this.timer = setTimeout(this.exported, 4000);
        }
      });
    },
    fetchCategory() {
      this.selectedT1 = null;
      this.optionsT1 = [];
      if (!this.task.id) {
        return;
      }
      fetchCategory(this.task.id).then((response) => {
        this.optionsT1 =
          response.result && response.result.items ? response.result.items : [];
      });
    },
    fetchItem() {
      this.selectedT2 = null;
      this.optionsT2 = [];
      if (!this.task.id || !this.selectedT1) {
        return;
      }
      fetchItem(this.task.id, this.selectedT1).then((response) => {
        this.optionsT2 =
          response.result && response.result.items ? response.result.items : [];
      });
    },
  },
  computed: {
    imageOriginalSize() {
      const len = 400;
      let height = len,
        width = len;
      if (
        this.current &&
        this.current.image.width > 0 &&
        this.current.image.height > 0
      ) {
        width = (height / this.current.image.height) * this.current.image.width;
        if (width > len) {
          width = len;
          height =
            (width / this.current.image.width) * this.current.image.height;
        }
      }
      return { height: height + "px", width: width + "px" };
    },
    colorStyles() {
      if (!this.current || !this.current.color) {
        return [];
      }
      let colors = JSON.parse(this.current.color);
      return colors.map((v) => {
        if (!v.rgb || v.rgb.length !== 3) {
          return null;
        }
        return "rgb(" + v.rgb[0] + "," + v.rgb[1] + "," + v.rgb[2] + ")";
      });
    },
    showClassify() {
      return (
        this.task &&
        (this.task.status === "done" ||
          this.task.status === "abort" ||
          this.task.status === "error")
      );
    },
    trendPoint() {
      if (!this.current || !this.current.trendPoints) {
        return "";
      }
      let v = "";
      for (let key in this.current.trendPoints) {
        if (v !== "") {
          v += ", ";
        }
        v += this.current.trendPoints[key];
      }
      return v;
    },
  },
};
</script>

<style lang="scss" scoped>
.tagger-detail {
  .summary-content {
    color: #606266;
    background-color: #fff;
    padding: 2px 10px;
  }
  .el-row {
    margin: 10px 0;
  }
  .summary-title {
    font-weight: bold;
    margin-right: 8px;
  }
  .summary-text {
    display: inline;
    vertical-align: middle;
  }
  .search-form {
    float: left;
    height: 32px;
    margin-left: 10px;
  }
  .summary-actions {
    text-align: right;
  }
  .table-avatar {
    vertical-align: middle;
    border: 1px solid #e8e8e8;
  }
  .detail-dialog /deep/ .el-dialog {
    margin-bottom: 3vh;
  }
  .detail-dialog .el-row {
    margin-bottom: 8px;
  }
  .detail-dialog {
    .detail-img {
      background: #fafafa;
      text-align: center;
      margin: 0 auto;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      line-height: 0;
    }

    .frame {
      background: #fafafa;
      text-align: center;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      line-height: 0;

      .frame-zone {
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }
    }

    .detail-desc {
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      width: 100%;
      text-align: left;
      overflow: hidden;

      table {
        width: 100%;
        border-collapse: collapse;
      }
      .desc-row {
        border-bottom: 1px solid #e8e8e8;
        font-size: 14px;
        line-height: 1.5;

        .desc-row-title {
          padding: 12px 24px;
          background-color: #fafafa;
          color: rgba(0, 0, 0, 0.85);
          font-weight: normal;
          white-space: nowrap;
        }

        .desc-row-content {
          padding: 12px 24px;
          color: rgba(0, 0, 0, 0.65);

          .desc-content-label {
            color: #a9a9a9;
            margin-right: 10px;
            line-height: 27px;
          }

          .color-container {
            display: inline;
            line-height: 27px;
          }

          .color-block {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            vertical-align: text-top;
          }
        }

        .desc-row-content > p {
          margin: 0;
        }
      }
      .desc-row:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>