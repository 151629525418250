<template>
  <div class="tagger-running">
    <el-col :offset="8" :span="8">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="
        current && current.imageCount
          ? parseInt((current.detectedCount / current.imageCount) * 100)
          : 0
      "></el-progress>
      <p>{{ $t("tagger.runningTagger") }}</p>
      <p>
        {{
          $t("tagger.finishImages", {
            finishCount: current ? current.detectedCount : 0,
            totalCount: current ? current.imageCount : 0,
          })
        }}
      </p>
      <el-button class="abort-btn" type="danger" icon="el-icon-switch-button" size="small"
        @click="abort">Abort</el-button>
    </el-col>
  </div>
</template>

<script>
import { abort } from "@/api/task";

export default {
  props: {
    current: {
      type: Object,
      default: () => {
        return {
          detectedCount: 0,
          imageCount: 1,
        };
      },
    },
    abortCallback: {
      type: Function,
    },
  },
  methods: {
    abort() {
      abort(this.current.id).then((response) => {
        if (response.success && this.abortCallback) {
          this.abortCallback();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.tagger-running {
  text-align: center;
  padding: 50px 0;

  p {
    font-size: 14px;
    font-weight: bold;
  }

  .abort-btn {
    margin-bottom: 20px;
  }
}
</style>